document.querySelectorAll('.menu a[href^="#"]').forEach(anchor => {
	anchor.addEventListener('click', function (e) {
		e.preventDefault();
 
		document.querySelector(this.getAttribute('href')).scrollIntoView({
			behavior: 'smooth'
		});
	});
});

AOS.init({
	easing: 'ease-in-out-sine'
});

let splide = new Splide( '.splide', {
	autoplay: true,
	pauseOnHover: false,
	pauseOnFocus: false,
	perPage: 1,
	perMove: 1,
	type: 'loop',
	wheel: false,
	releaseWheel: true,
	waitForTransition: true,
	wheelSleep: 200,
	interval: 3000,
	drag: true,
	arrows: false,
	gap: 50,
	padding: { top: 10, bottom: 20 },
	width: ""
});

function getDataGoogleDisc() {
	let googleDiscDescriptionFile = "1xknzTdskFpqk_JsuihPoF04wtyI3D6S4gQuNjAQ9jTg"
	let dataURL = "https://docs.google.com/spreadsheets/d/" + googleDiscDescriptionFile + "/gviz/tq?tqx=out:json"
	fetch(dataURL)
		.then(response => {
			return response.text();
		})
		.then(data => {
			let json_string = data.substring(47).slice(0, -2);
			let details = JSON.parse(json_string);
			let table = details.table;
			
			let obj = {};
			let inObj = {};

			table.rows.forEach((row, indexRow) => {
				
				table.cols.forEach((col, indexCol) => {

					let f = col.label;
					let v = row.c[indexCol] != null ? row.c[indexCol].v : '';

					Object.defineProperty(inObj, f, {
						value: v
					});

				});

				Object.defineProperty(obj, indexRow, {
					value: inObj
				});

				inObj = {}
			})
			console.log(obj)

			return obj;
		})
		.catch(error => {
			console.log(error)
		})
}




let ready = (callback) => {
	if (document.readyState != "loading") callback();
	else document.addEventListener("DOMContentLoaded", callback);
}

let dataPrice = undefined

ready(() => {
	splide.mount();
	document.$imetalDataPrice = dataPrice = getDataGoogleDisc();
});

// window.scroll({
// 	top: 0,
// 	left: 0,
// 	behavior: 'smooth'
// });

//document.addEventListener('scroll')


function onClickSideBarBtn () {
	var sidebarbtn = document.getElementById("sidebarbtn");
	sidebarbtn.classList.toggle("active");

	var menu__wrapper = document.getElementById("menu__wrapper");
	var wrapper__tel__menu = document.getElementById("wrapper__tel__menu");
	var sidebarbtn__wrapper = document.getElementById("sidebarbtn__wrapper");

	var hmw = menu__wrapper.offsetHeight;
	var hsw = sidebarbtn__wrapper.offsetHeight;

	var delta = hmw - hsw

	let html = document.querySelector(`html`);
	html.style.setProperty(`--delta-height`, -delta+"px");

	menu__wrapper.classList.toggle("active");
	wrapper__tel__menu.classList.toggle("visible");
	
}

